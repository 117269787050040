export const url = "https://booking.boattorch.com/api";
// export const url = "http://localhost/api";

export const dateToFormattedString = (date) => {
  const date_str = date.toISOString().slice(0, 16);
  return date_str;
};

export const formattedStringToDate = (date_str) => {
  const newDate = new Date(date_str);
  return newDate;
};

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const sortUnits = (a, b) => {
    if (a.unit_id > b.unit_id) {
      return 1;
    } else {
      return -1;
    }
  }

export const capitalizeFirstLetter = (string) => {
    return (string[0].toUpperCase() + string.slice(1));
}

export const put = async (route, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        headers: myHeaders,
        method: "PUT",
        body: data,
    };

    try {
        const response = await fetch(`${url + route}`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}

export const post = async (route, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        headers: myHeaders, 
        method: "POST",
        body: data,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${url + route}`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}

export const get = async (route) => {
    const requestOptions = {
        method: "GET",
    };

    try {
        const response = await fetch(`${url + route}`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error);
        return
    }
}

export const del = async (route) => {
    const requestOptions = {
        method: "DELETE",
    };

    try {
        const response = await fetch(`${url + route}`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error);
        return
    }
}

export const getPDF = async (route) => {
    const requestOptions = {
        method: "GET",
    };

    try {
        const response = await fetch(`${url + route}`, requestOptions);
        const result = await response
        console.log("GetPDF response:", result)
        return result;

    } catch (error) {
        console.error(error);
        return
    }
}