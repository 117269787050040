import React from "react";
import "./displayHeading.css";

function DisplayHeading({ step, setStep, title }) {
  return (
    <div className="container title-container">
        <p className="title">{title}</p>
        <button className={'btn edit-btn ' + (!setStep && 'hidden')} onClick={() => setStep(step)}>Edit</button>
    </div>
  )
}

export default DisplayHeading;