import React, { useEffect, useState } from 'react';
import "./progressBar.css"

function ProgressBar({step, setStep, selectionsDone, infoDone, complete, error}) {
    function handleStepClick(clickedStep) {
        switch (clickedStep) {

            case 0:
                if (step < 3) {
                    setStep(0);
                }
                break;

            case 1:
                if (selectionsDone && step < 3) {
                    setStep(1);
                }
                break;

            case 2:
                if ((infoDone && selectionsDone) && step < 3) {
                    setStep(2);
                }
                break;
                
            default:
                break;
        }
    }

    return (
        <div className='progress-bar-container'>
            <div onClick={() => handleStepClick(0)} className={
                'progress-bar-step ' + 
                (step===0 ? 'current ' : '') +
                (selectionsDone ? 'complete ' : '')
                }>selections</div>
            <div onClick={() => handleStepClick(1)} className={
                'progress-bar-step ' + 
                (step===1 ? 'current ' : '') +
                (infoDone ? 'complete ' : '')
                }>info</div>
            <div onClick={() => handleStepClick(2)} className={
                'progress-bar-step ' + 
                (step===2 ? 'current ' : '') +
                (complete ? 'complete ' : '')
                }>payment</div>
            <div className={
                'progress-bar-step ' + 
                (step===3 ? 'current ' : '') +
                (complete ? 'complete ' : '')
                }>complete</div>
        </div>
    );
};

export default ProgressBar;
