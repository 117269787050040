import React, { useEffect, useRef } from "react";
import "./bookingComplete.css";

function BookingComplete({ email }) {
    const containerRef = useRef(null);
  
    useEffect(() => {
      containerRef.current.focus();
      window.scrollTo({
        top: containerRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }, [])

  return (
    <div ref={containerRef} className="complete-container main-border">
      <p className="title">Booking Confirmed!</p>
      <p>Thank you for booking with us! Your reservation is confirmed. </p>
      {(email !== "") && (
        <div>
        <span>You'll recieve a confirmation email to </span>
        <strong>{email}</strong>
        <span> with your booking details shortly.</span>
        </div>
      )}
      <button className="btn" onClick={() => window.location.reload()}>Return To Booking Calendar</button>
    </div>
  )
}

export default BookingComplete;