import React, { useEffect, useState } from 'react';
import * as helpers from "../../utils/utils.js"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import "./bookingDetails.css"


const BookingDetails = ({ booking_id, bookingDates, setBookingDates, units }) => {
  const [bookingInfo, setBookingInfo] = useState({});
  const [renterInfo, setRenterInfo] = useState({});
  const [costInfo, setCostInfo] = useState({});
  const [openModal, setOpenModal] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [avaibableDates, setAvailableDates] = useState([]);
  const [newBookingDate, setNewBookingDate] = useState({});

  
  const handleBookDateChange = (e) => {
    const { name, value } = e.target;
  
    setNewBookingDate((values) => ({ ...values, [name]: value }));
  };

  const handleCostChange = (e) => {
    const { name, value } = e.target;
  
    setCostInfo((values) => ({ ...values, [name]: value }));
  };



  const handleBookDateSubmit = async (e, id) => {
    e.preventDefault();

    try {
        let result;
        if (id === "add") {
            result = await helpers.put(`/booking_dates`, JSON.stringify({...newBookingDate, "booking_id": booking_id, "client_id": bookingInfo.client_id}));
        } else {
            result = await helpers.put(`/booking_dates?id=${id}`, JSON.stringify(newBookingDate));
        }

        if (result) {
            if (id === "add") {
                setBookingDates(oldBookingDates => (
                    [ ...oldBookingDates, {...newBookingDate, "booking_id": booking_id, "client_id": bookingInfo.client_id}]
                    )
                );
            } else {
                setBookingDates(oldBookingDates =>
                    oldBookingDates.map(oldBookingDate =>
                        oldBookingDate.id === id ? { ...oldBookingDate, ...newBookingDate } : oldBookingDate
                    )
                );
            }
            
            setNewBookingDate({});
            setExpandedRow(null);
        } else {
            console.error('Failed to update booking date');
        }
    } catch (error) {
        console.error('An error occurred while updating booking date:', error);
    }
  };

  const handleCostSubmit = async (e) => {
    try {
        e.preventDefault();

        let result;
        if (!costInfo.id) {
            result = await helpers.put(`/costs`, JSON.stringify({...costInfo, "booking_id": booking_id}));
        } else {
            result = await helpers.put(`/costs?id=${costInfo.id}`, JSON.stringify(costInfo));
        }

        

        if (result) {
            console.log("Cost Successfully Changed");
        }

    } catch (error) {
        console.error('An error occurred while updating cost info:', error);
    }
  };

  const removeBookDate = async (id) => {
    try {
        const result = await helpers.del(`/booking_dates?id=${id}`);

        if (result) {
            setBookingDates(oldBookingDates => (
                oldBookingDates.filter((oldDate) => oldDate.id !== id)
            ));
            setExpandedRow(null);
            setNewBookingDate({});

        } else {
            console.error('Failed to remove booking date');
        }
    } catch (error) {
        console.error('An error occurred while removing booking date:', error);
    }
  }

  const handleRowClick = (id) => {
      setExpandedRow(expandedRow === id ? null : id);
      setNewBookingDate({});
  };

  const handleModalOpenClick = (id) => {
      setOpenModal(id);
      setNewBookingDate({});
  };

  const handleModalCloseClick = () => {
    setOpenModal(null);
  };
  

  useEffect(() => {
    async function getAvailability(client_id) {
        let startDate = new Date();
        startDate.setUTCHours(4, 0);
        const endDate = helpers.addDays(startDate, 365);
        
        const startDate_str = helpers.dateToFormattedString(startDate);
        const endDate_str = helpers.dateToFormattedString(endDate);
    
        const result = await helpers.get(`/available_units?client_id=${client_id}&start_date=${startDate_str}&end_date=${endDate_str}`);
    
        result ? setAvailableDates([...result]) : setAvailableDates([]);
    }

    bookingInfo.client_id && getAvailability(bookingInfo.client_id);

  }, [bookingInfo.client_id])

  useEffect(() => {
    async function getRenterInfo() {
        const result = await helpers.get(`/users?id=${bookingInfo.user_id}`);
        return result[0] ?? {};
    } 


    bookingInfo.user_id && getRenterInfo().then((result) => {
        setRenterInfo(result)
    }).catch((console.error("Could Not Retreive Renter Info")))


  }, [bookingInfo.user_id])

  useEffect(() => {

    async function getCostInfo() {
        const result = await helpers.get(`/costs?booking_id=${bookingInfo.id}`);
        return result[0] ?? {};
    } 

    bookingInfo && getCostInfo().then((result) => {
        result.rentRecieved ?? (result.rentRecieved = bookingInfo.totalPrice - bookingInfo.totalDeposit);
        result.depositRecieved ?? (result.depositRecieved = bookingInfo.totalDeposit);
        result.gasRecieved ?? (result.gasRecieved = 0);
        result.miscRecieved ?? (result.miscRecieved = 0);

        result.rentType ?? (result.rentType = "card");
        result.depositType ?? (result.depositType = "card");
        result.gasType ?? (result.gasType = "card");
        result.miscType ?? (result.miscType = "card");
    
        setCostInfo(result)
    }).catch((console.error("Could Not Retreive Cost Info")))


  }, [bookingInfo])

  useEffect(() => {

    async function getBookingInfo() {
        const result = await helpers.get(`/bookings?id=${booking_id}`);
        return result[0] ?? {};
    } 

    booking_id && getBookingInfo().then((result) => {
        setBookingInfo(result)
    }).catch((console.error("Could Not Retreive Booking Info")))


  }, [booking_id])

//   useEffect(() => {
//     console.log("bookingdates", bookingDates)
//   }, [bookingDates])



  return (
    <div className='booking-details-container'>
        <div className='booking-info'>
            <p><strong>Contract Date</strong>: {helpers.formattedStringToDate(bookingInfo.contractDate).toLocaleDateString()}</p>
            {renterInfo && (<p><strong>Renter Name</strong>: {renterInfo.firstName} {renterInfo.lastName}</p>)}
            <Button variant="primary" onClick={handleModalOpenClick}>
                View Full Booking Info
            </Button>
            <Modal show={openModal} onHide={handleModalCloseClick}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <table className="table date-table">
                    <thead>
                        <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Price</th>
                        <th scope="col">Deposit</th>
                        <th scope="col">Unit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookingDates.map((bookDate) => (
                            <React.Fragment key={bookDate.id}>
                            <tr className={"booking-date-row"} onClick={() => handleRowClick(bookDate.id)}>
                                <td className='date'>{helpers.formattedStringToDate(bookDate.date).toLocaleDateString()}</td>
                                <td className='price'>${bookDate.price}</td>
                                <td className='deposit'>${bookDate.deposit}</td>
                                <td className='unit'>{units.find(unit => unit.id === bookDate.unit_id)?.name || 'Unknown Unit'}</td>
                            </tr>
                            {expandedRow === bookDate.id && (
                                <tr>
                                    <td colSpan="5">
                                        <div className="expanded-content">
                                        <form className="renter-form" onSubmit={(e) => handleBookDateSubmit(e, bookDate.id)}>

                                            <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Date</label>
                                                <select
                                                    className="form-control"
                                                    id="date"
                                                    name="date"
                                                    onChange={handleBookDateChange}
                                                    >
                                                    <option value={bookDate.date}>{new Date(bookDate.date).toLocaleDateString()}</option>
                                                    {avaibableDates.sort((a, b) => new Date(a.date) - new Date(b.date))
                                                    .map((avaibableDate) => (
                                                    <option key={avaibableDate.date} 
                                                    value={avaibableDate.date}>{new Date(avaibableDate.date).toLocaleDateString()} ${avaibableDate.price} Unit #{avaibableDate.unit_id}</option>))}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Unit</label>
                                                <select
                                                className="form-control"
                                                name="unit_id"
                                                value={newBookingDate.unit_id ?? bookDate.unit_id}
                                                onChange={handleBookDateChange}
                                                >
                                                    {units.map((unit) => (
                                                        <option key={unit.id} value={unit.id}>{unit.name || 'Unknown Unit'}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            </div>

                                        

                                            <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Price</label>
                                                <input
                                                className="form-control"
                                                type="number"
                                                name="price"
                                                value={newBookingDate.price ?? bookDate.price}
                                                onChange={handleBookDateChange}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Deposit</label>
                                                <input
                                                className="form-control"
                                                type="number"
                                                name="deposit"
                                                value={newBookingDate.deposit ?? bookDate.deposit}
                                                onChange={handleBookDateChange}
                                                />
                                            </div>
                                            </div>

                                            <div className='form-buttons'>
                                                <button onClick={() => removeBookDate(bookDate.id)} className='btn btn-danger'>Remove</button>
                                                <button type="submit" className='btn'>Update</button>
                                            </div>
                                           
                                        </form>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </React.Fragment>
                        ))}
                        <tr className={"booking-date-row add"}>
                            <td colSpan="5" className='date' onClick={() => handleRowClick("add")}>+ Add Booking Date</td>
                        </tr>
                        {expandedRow === "add" && (
                        <tr>
                            <td colSpan="5">
                                <div className="expanded-content">
                                <form className="renter-form" onSubmit={(e) => handleBookDateSubmit(e, "add")}>

                                    <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>Date</label>
                                        <select
                                            required
                                            className="form-control"
                                            id="date"
                                            name="date"
                                            onChange={handleBookDateChange}
                                            >
                                            <option value="">Select A Date</option>
                                            {avaibableDates.sort((a, b) => new Date(a.date) - new Date(b.date))
                                            .map((avaibableDate) => (
                                            <option key={avaibableDate.date} 
                                            value={avaibableDate.date}>{new Date(avaibableDate.date).toLocaleDateString()} ${avaibableDate.price} Unit #{avaibableDate.unit_id}</option>))}
                                        </select>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Unit</label>
                                        <select
                                        required
                                        className="form-control"
                                        name="unit_id"
                                        onChange={handleBookDateChange}
                                        >
                                             <option value="">{'Select A Unit'}</option>
                                            {units.map((unit) => (
                                                <option value={unit.id}>{unit.name || 'Unknown Unit'}</option>
                                            ))}
                                        </select>
                                    </div>

                                    </div>

                                

                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Price</label>
                                        <input
                                        required
                                        className="form-control"
                                        type="number"
                                        name="price"
                                        value={newBookingDate.price ?? 0}
                                        onChange={handleBookDateChange}
                                        />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Deposit</label>
                                        <input
                                        required
                                        className="form-control"
                                        type="number"
                                        name="deposit"
                                        value={newBookingDate.deposit ?? 0}
                                        onChange={handleBookDateChange}
                                        />
                                    </div>
                                    </div>


                                    {/* <div className="form-row">
                                    
                                    <div className="form-group col-md-6">
                                        <label>Duration (Hours)</label>
                                        <input
                                        className="form-control"
                                        type="number"
                                        name="duration"
                                        value={newBookingDate.duration ?? 0}
                                        onChange={handleBookDateChange}
                                        />
                                    </div>
                                    </div> */}

                                    <div className='form-button'><button className="btn" type="submit" >Add</button></div>
                                </form>
                                </div>
                            </td>
                        </tr>
                        )}
                        
                    </tbody>
                </table>

                <div>
                <form className="renter-form" onSubmit={(e) => handleCostSubmit(e)}>

                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Gas Paid ($)</label>
                                        <input
                                        className="form-control"
                                        type="number"
                                        name="gasRecieved"
                                        value={costInfo.gasRecieved}
                                        onChange={handleCostChange}
                                        />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Gas Payment Type</label>
                                        <select
                                        className="form-control"
                                        name="gasType"
                                        value={costInfo.gasType}
                                        onChange={handleCostChange}
                                        >
                                            <option value={"card"}>Card</option>
                                            <option value={"cash"}>Cash</option>
                                            <option value={"venmo"}>Venmo</option>
                                            <option value={"other"}>Other</option>
                                        </select>
                                    </div>
                                    </div>

                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Rent Paid On Pickup $</label>
                                        <input
                                        className="form-control"
                                        type="number"
                                        name="rentRecieved"
                                        value={costInfo.rentRecieved}
                                        onChange={handleCostChange}
                                        />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Rent Type</label>
                                        <select
                                        className="form-control"
                                        name="rentType"
                                        value={costInfo.rentType}
                                        onChange={handleCostChange}
                                        >
                                            <option value={"card"}>Card</option>
                                            <option value={"cash"}>Cash</option>
                                            <option value={"venmo"}>Venmo</option>
                                            <option value={"other"}>Other</option>
                                        </select>
                                    </div>
                                    </div>

                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Deposit Paid ($)</label>
                                        <input
                                        className="form-control"
                                        type="number"
                                        name="depositRecieved"
                                        value={costInfo.depositRecieved}
                                        onChange={handleCostChange}
                                        />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Deposit Type</label>
                                        <select
                                        className="form-control"
                                        name="depositType"
                                        value={costInfo.depositType}
                                        onChange={handleCostChange}
                                        >
                                            <option value={"card"}>Card</option>
                                            <option value={"cash"}>Cash</option>
                                            <option value={"venmo"}>Venmo</option>
                                            <option value={"other"}>Other</option>
                                        </select>
                                    </div>
                                    </div>

                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Misc Paid ($)</label>
                                        <input
                                        className="form-control"
                                        type="number"
                                        name="miscRecieved"
                                        value={costInfo.miscRecieved}
                                        onChange={handleCostChange}
                                        />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Misc Type</label>
                                        <select
                                        className="form-control"
                                        name="miscType"
                                        value={costInfo.miscType}
                                        onChange={handleCostChange}
                                        >
                                            <option value={"card"}>Card</option>
                                            <option value={"cash"}>Cash</option>
                                            <option value={"venmo"}>Venmo</option>
                                            <option value={"other"}>Other</option>
                                        </select>
                                    </div>
                                    </div>

                                    <div className='form-button'><button className="btn" type="submit" >Update</button></div>
                                </form>

                    <a className='btn' href={`/api/contract?booking_id=${String(booking_id)}`} target='_blank' rel='noopener noreferrer'>View Contract</a>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCloseClick}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )

}

export default BookingDetails;