// DiscountForm.js
import React, { useEffect, useState } from 'react';
import * as helpers from "../../utils/utils.js"
import "./discountForm.css"

const DiscountForm = ({ discounts, setDiscounts, client_id}) => {
  const [discountCode, setDiscountCode] = useState('');
  const [validationMessage, setValidationMessage] = useState(null);

  useEffect(() => {
    setValidationMessage(null);
  }, [discountCode])  

  async function handleFormSubmit(e) {
    e.preventDefault();
    // check discount codes with request

    if (discountCode === "") {
      setValidationMessage("Enter Discount Code");
      return
    }

    const alreadyUsedCode = discounts.find(discount => discount.name === discountCode);
    if (alreadyUsedCode) {
      setValidationMessage("Code Already Used");
      return
    }

    const validDiscountCodes = await helpers.get(`/rebates?client_id=${client_id}`)

    console.log(validDiscountCodes)
    const newDiscountCode = validDiscountCodes.find(discount => discount.name === discountCode);
    if (newDiscountCode) {
      setDiscounts([...discounts, newDiscountCode]);
      setDiscountCode('');
      return;
    }

    setValidationMessage("Discount Code Invalid");
      return


  }

  function handleInputChange(event) {
    setDiscountCode(event.target.value);
  }
  
  return (
    <div className='discount-container'>
      {/* <div className='instructions'>Enter Discount or Promo Code Below.</div> */}
    <form onSubmit={(e) => handleFormSubmit(e)}>
      <div className='discount-form'>
        <div className='discount-group'>
          
          <input
            placeholder='Enter Discount Code'
            type="text"
            name="discountCode"
            value={discountCode}
            onChange={handleInputChange}
            className='form-control'
          />

          { validationMessage && (
            <div className="invalid">
              {validationMessage}
            </div>
          )}
          

        </div>
        <button className="btn discount-sbmt-btn" type="submit">Apply</button>
      </div>
    </form>
    </div>
  );
};

export default DiscountForm;
