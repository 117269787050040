import React from "react";
import "./renterInfoDisplay.css";
import DisplayHeading from "../displayHeading/displayHeading";

function RenterInfoDisplay({ renterInfo, setStep }) {
  return (
    <div className="info-display-container main-border">
      <DisplayHeading
        setStep={setStep}
        step={1}
        title={"Info"}
      />

      <div className="container renter-info">
        <div className="info-row">{renterInfo.firstName} {renterInfo.lastName}</div>
        <div className="info-row">{renterInfo.email}</div>
        <div className="info-row">{renterInfo.phone}</div>
        <div className="info-row">{renterInfo.address}</div>
        <div className="info-row">{renterInfo.city}, {renterInfo.state} {renterInfo.zip}</div>
      </div>
    </div>
  );
}

export default RenterInfoDisplay;
