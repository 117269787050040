import React, { useEffect, useState } from "react";
// import "./Admin.css";
import * as helpers from "../utils/utils.js";
import BookingTable from "../components/bookingTable/bookingTable.js";
import PriceDates from "../components/priceDates/priceDates.js";


function Admin() {
    const [client, setClient] = useState(2);
    const [bookingDates, setBookingDates] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [units, setUnits] = useState([]);
    const [priceDates, setPriceDates] = useState([]);

    useEffect(() => {
    
        async function getBookingDates() {
            const result = await helpers.get(`/booking_dates?client_id=${client}`);
            return result ? result.sort((a, b) => new Date(a.date) - new Date(b.date)) : [];
        }

        async function getBookings() {
            const result = await helpers.get(`/bookings?client_id=${client}`);
            return result ?? [];
        }

        async function getUnits() {
            const result = await helpers.get(`/units?client_id=${client}`);
            return result ?? [];
        }

        async function getPriceDates() {
            const result = await helpers.get(`/price_dates?client_id=${client}`);
            return result ?? [];
        }


        if (client) {

            // loads all booking dates for this client
            getBookingDates().then((result) => {
                setBookingDates(result)
            }).catch((console.error("Could Not Retreive Booking Dates")))

            // loads all bookings for this client
            getBookings().then((result) => {
                setBookings(result)
            }).catch((console.error("Could Not Retreive Bookings")))
            
            // loads all units for this client
            getUnits().then((result) => {
                setUnits(result);
                console.log(result);
            }).catch((console.error("Could Not Retreive Units ")))

            // loads all price dates for this client
            getPriceDates().then((result) => {
                setPriceDates(result)
            }).catch((console.error("Could Not Retreive Price Dates")))
        }

    }, [client])



    return (
        <>
        {/* <BookingDateTable
            units={units}
            bookingDates={bookingDates}
            bookings={bookings}
        /> */}

        <a className='btn' href={`/api/bookkeep?client_id=${String(client)}`}>Download Bookkeeping CSV</a>

        <BookingTable
            units={units}
            bookingDates={bookingDates}
            setBookingDates={setBookingDates}
            bookings={bookings}
        />

{/* 
        <PriceDates
            units={units}
            priceDates={priceDates}
        /> */}
        </>
    )

}

export default Admin;