import React, { useEffect, useState } from 'react';
import * as helpers from "../../utils/utils.js"
import "./navBar.css"

function NavBar({step, setStep, disabled}) {
    const steps = ["selections", "info", "payment"]

    return (
        <div className='nav-bar'>
            <button disabled={disabled()} onClick={() => setStep(step+1)} className='btn continue-btn'>Continue to {helpers.capitalizeFirstLetter(steps[step+1])}</button>
        </div>
    );
};

export default NavBar;
