import React, { useEffect, useState, useRef } from "react";
import DiscountForm from "../discountForm/discountForm.js";
import * as helpers from "../../utils/utils.js";
import "./unitPaymentForm.css";
import { ApplePay, CreditCard, GooglePay, PaymentForm } from "react-square-web-payments-sdk";

// tile that shows unit information
function UnitPaymentForm({ getPaymentSuccessful, deposit, setStep, step, squareCredentials, client_id, discounts, setDiscounts }) {
  const cardRef = useRef(null);
  const [payDisabled, setPayDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: cardRef.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }, [])

  const createPayment = async (token, amount) => {
    setStep(5)

    const data = JSON.stringify({
      token: token.token,
      amount: amount,
      client_id: client_id
    })

    const result = await helpers.post('/payment', data);

    if (result?.payment?.status === "COMPLETED") {
      getPaymentSuccessful(true);
    } else {
      getPaymentSuccessful(false);
    }

    setPayDisabled(false)
    

  };

  return (
    <div ref={cardRef} className="payment-container main-border">
      
      <div className="payment-heading">
        <p>Payment</p>

        <DiscountForm 
          discounts={discounts}
          setDiscounts={setDiscounts}
          client_id={client_id}
        />

        <div className="instructions">
          Please enter your payment information below. Fuel cost will be charged separately.
        </div>

        <div className="deposit">
          <span>Deposit Due Today: ${deposit}</span>
        </div>
      </div>

      
      <div className="payment-form" >
        
        <PaymentForm
          
          applicationId={squareCredentials['app_id']}
          
          cardTokenizeResponseReceived={(token, verifiedBuyer) => {
            setPayDisabled(true);
            createPayment(token, String(deposit*100));
          }}
          
          locationId={squareCredentials['location_id']}
          
          createPaymentRequest={() => ({
            countryCode: "US",
            currencyCode: "USD",
            total: {
              amount: String(deposit),
              label: "Deposit",
            },
          })}
        >
          
          {/* <GooglePay className="google-pay"/> */}
          <CreditCard buttonProps={{
            // isLoading: true,
            // onSubmit: () => payClick,
          }}/>
          {/* <ApplePay /> */}
        </PaymentForm>
      </div>
    </div>
  );
}

export default UnitPaymentForm;
