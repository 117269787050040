import React, { useEffect, useState } from 'react';
import * as helpers from "../../utils/utils.js"
import "./bookingTable.css"
import BookingDetails from '../bookingDetails/bookingDetails.js';


const BookingTable = ({ bookingDates, setBookingDates, units, bookings }) => {
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (id) => {
      setExpandedRow(expandedRow === id ? null : id);
  };

  useEffect(() => {
    console.log(bookingDates);
  }, [bookingDates])
  
  return (
    <div className='booking-date-container main-border'>
        <div className='container date-details'>

            {bookingDates.length > 0 && (
            <table className="table date-table">
              <thead>
                <tr>
                <th scope="col">Date</th>
                <th scope="col">Price</th>
                <th scope="col">Deposit</th>
                <th scope="col">Unit</th>
                <th scope="col">ID</th>
                </tr>
              </thead>
              <tbody>
                {bookingDates.map((bookDate) => (
                    <React.Fragment key={bookDate.id}>
                    <tr className="booking-date-row" onClick={() => handleRowClick(bookDate.id)}>
                        <td className='date'>{helpers.formattedStringToDate(bookDate.date).toLocaleDateString()}</td>
                        <td className='price'>${bookDate.price}</td>
                        <td className='deposit'>${bookDate.deposit}</td>
                        <td className='unit'>{units.filter((unit) => unit.id === bookDate.unit_id)[0]?.name || 'Unknown Unit'}</td>
                        <td className='id'>{bookDate.booking_id}</td>
                    </tr>
                    {expandedRow === bookDate.id && (
                        <tr>
                            <td colSpan="5">
                                <div className="expanded-content">
                                    <BookingDetails 
                                        booking_id={bookDate.booking_id} 
                                        bookingDates={bookingDates.filter((date) => date.booking_id === bookDate.booking_id)}
                                        setBookingDates={setBookingDates}
                                        units={units}
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                    </React.Fragment>
                ))}
                  
              </tbody>
            </table>
            )}
        </div>
      
      
    </div>
  );
};

export default BookingTable;
