import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "./bookingCalendar.css";
import * as helpers from "../../utils/utils.js";


function BookingCalendar({ client_id, setVisibleUnits, visibleUnits, setSelectedUnits, selectedUnits, setSelectedDates, selectedDates }) {
  // const [selectedDates, setSelectedDates] = useState([]); // list of selected dates
  const [availableUnits, setAvailableUnits] = useState([]); // list of avaibable units w/date
  const [availableDates, setAvailableDates] = useState([]);  // list of partial/fully available dates

  const yesterday = new Date(Date.now() - 86400000); // 86400000 milliseconds = 1 day
  const legendItems = ["unavailable", "available", "selected"];

  function handleClickDay(value, event) {
    value.setUTCHours(4, 0);

    const dateString = helpers.dateToFormattedString(value);
    
    let updatedDates = [...selectedDates];
    let updatedVisibleUnits = [...visibleUnits];
    let updatedSelectedUnits = [...selectedUnits];

    if (updatedDates.includes(dateString)) {
      updatedDates = updatedDates.filter(date => date !== dateString);
      updatedSelectedUnits = updatedSelectedUnits.filter(unit => unit.date !== dateString)
      updatedVisibleUnits = updatedVisibleUnits.filter(unit => unit.date !== dateString);
      
      setSelectedUnits(updatedSelectedUnits);
    } else {
      updatedDates.push(dateString);
      let unitsToAdd = availableUnits.filter(unit => unit.date === dateString);
      unitsToAdd = unitsToAdd.sort((a,b) => helpers.sortUnits(a,b));
      updatedSelectedUnits.push(unitsToAdd[0]);
      updatedVisibleUnits.push(...unitsToAdd);

      setSelectedUnits(updatedSelectedUnits);
    }

    setVisibleUnits(updatedVisibleUnits);
    setSelectedDates(updatedDates);
  };

  /**
   * determines which classname to add to a tile depending on
   * availability of the date
   * @param {Date} date date of the tile
   * @returns {string} name of the class to be added
   */
  function updateCalenderTiles({ date }) {

    date.setUTCHours(4, 0);
    const dateString = helpers.dateToFormattedString(date);

    if (selectedDates.includes(dateString)) {
      return "selected ";
    } else if (availableDates.includes(dateString)) {
      return "available";
    } else {
      return "unavailable";
    }
  };

  function isTileDisabled({date}) {

    date.setUTCHours(4, 0);
    const dateString = helpers.dateToFormattedString(date);
    if (date > yesterday && availableDates.includes(dateString)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * gets availability from the server and fills availability
   * state variable with the availbility for the given month
   * based on the start date
   * @param {Date} activeStartDate
   */
  async function getAvailability(activeStartDate) {
    const startDate = helpers.addDays(activeStartDate, -7);
    startDate.setUTCHours(4, 0);
    const endDate = helpers.addDays(startDate, 42);
    endDate.setUTCHours(4,0);
    
    const startDate_str = helpers.dateToFormattedString(startDate);
    const endDate_str = helpers.dateToFormattedString(endDate);

    const result = await helpers.get(`/available_units?client_id=${client_id}&start_date=${startDate_str}&end_date=${endDate_str}`);

    if (result) {

      let newAvailableUnits = [];
      let newAvailableDates = [];
      
      for (let idx in result) {
        const unit = result[idx];
        if (unit.price > 0) {
          newAvailableUnits.push({...unit});
          if (!newAvailableDates.includes(unit.date)) {
            newAvailableDates.push(unit.date);
          }
        } 
      }

      // console.log(newAvailableUnits);

      setAvailableUnits(newAvailableUnits);
      setAvailableDates(newAvailableDates);
    }
  }

  useEffect(() => {
    const d = new Date();
    d.setDate(1);
    getAvailability(d);
  }, []);

  return (
    <div className="calendar-container main-border">
      <div className="calendar-instructions">
        <div>
          <button 
            className="btn hidden" 
            onClick={() => {
              setSelectedUnits([]);
              setVisibleUnits([]);
              setSelectedDates([]);
            }}
            disabled={selectedDates.length < 1}
          >Clear</button>
        </div>
        <div><p className="section-heading">Select Dates</p></div>
        <div>
          <button 
            className="clear-btn btn" 
            onClick={() => {
              setSelectedUnits([]);
              setVisibleUnits([]);
              setSelectedDates([]);
            }}
            disabled={selectedDates.length < 1}
          >Clear</button>
        </div>
        
      </div>
      <Calendar
        tileClassName={updateCalenderTiles}
        tileDisabled={isTileDisabled}
        onClickDay={handleClickDay}
        onActiveStartDateChange={({activeStartDate}) => getAvailability(activeStartDate)}
        minDetail={"month"}
        calendarType="gregory"
      />
      <div className="calendar-legend secondary-border">
        {legendItems.map((item) => (
          <div key={item} className="item">
            <div className={"secondary-border color " + item }>a</div>
            <div className="label">{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BookingCalendar;
