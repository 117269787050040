import React, { useEffect, useRef, useState } from "react";
import "./renterInfoForm.css"

// tile that shows unit information
function RenterInfoForm({ renterInfo, setRenterInfo, errors, setStep }) {
  const firstNameRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    firstNameRef.current.focus();
    window.scrollTo({
      top: formRef.current.offsetTop - 200,
      left: 0,
      behavior: "smooth",
    });
  }, [])

  function handleFormChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setRenterInfo((values) => ({ ...values, [name]: value }));
  };

  return (
    <div ref={formRef} className="form-container main-border">
      <div className="renter-title">
        Info
      </div>
      <div className="renter-instructions">
        Please fill in your information as it should appear in you rental contract.
      </div>
      <form className="renter-form" onSubmit={(e) => e.preventDefault()}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>First Name</label>
            <input
              ref={firstNameRef}
              className="form-control"
              type="text"
              name="firstName"
              value={renterInfo.firstName}
              onChange={handleFormChange}
            />

            {errors.firstName && <span className="error-text">*{errors.firstName}</span>}
          
          </div>
          <div className="form-group col-md-6">
            <label>Last Name</label>
            <input
              className="form-control"
              type="text"
              name="lastName"
              value={renterInfo.lastName}
              onChange={handleFormChange}
            />

            {errors.lastName && <span className="error-text">*{errors.lastName}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Email</label>
            <input
              className="form-control"
              type="text"
              name="email"
              value={renterInfo.email}
              onChange={handleFormChange}
            />

            {errors.email && <span className="error-text">*{errors.email}</span>}
          </div>
          <div className="form-group col-md-6">
            <label>Phone</label>
            <input
              className="form-control"
              type="number"
              name="phone"
              value={renterInfo.phone}
              onChange={handleFormChange}
            />

            {errors.phone && <span className="error-text">*{errors.phone}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              name="address"
              value={renterInfo.address}
              onChange={handleFormChange}
            />

            {errors.address && <span className="error-text">*{errors.address}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>City</label>
            <input
              className="form-control"
              type="text"
              name="city"
              value={renterInfo.city}
              onChange={handleFormChange}
            />

            {errors.city && <span className="error-text">*{errors.city}</span>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="state">State</label>
            <select
              className="form-control"
              id="state"
              name="state"
              value={renterInfo.state}
              onChange={handleFormChange}
            >
              <option value="">Select a state</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
              <option value="AS">American Samoa</option>
              <option value="GU">Guam</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="PR">Puerto Rico</option>
              <option value="VI">U.S. Virgin Islands</option>
            </select>

          {errors.state && <span className="error-text">*{errors.state}</span>}
          </div>
          <div className="form-group col-md-2">
            <label>Zip</label>
            <input
              className="form-control"
              type="number"
              name="zip"
              value={renterInfo.zip}
              onChange={handleFormChange}
            />

            {errors.zip && <span className="error-text">*{errors.zip}</span>}
          </div>
        </div>
        <div className="nav-buttons">
        </div>
      </form>
    </div>
  );
}

export default RenterInfoForm;
