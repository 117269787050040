// Home.js

import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import ReactLoading from 'react-loading';
import "./Home.css";
import * as helpers from "../utils/utils.js";
import RenterInfoForm from "../components/renterInfoForm/renterInfoForm.js";
import UnitPaymentForm from "../components/unitPaymentForm/unitPaymentForm.js";
import UnitTileList from "../components/unitTileList/unitTileList.js";
import PriceDisplay from "../components/priceDisplay/priceDisplay.js";
import BookingCalendar from "../components/bookingCalendar/bookingCalendar.js";
import ProgressBar from "../components/progressBar/progressBar.js";
import NavBar from "../components/navBar/navBar.js";
import RenterInfoDisplay from "../components/renterInfoDisplay/renterInfoDisplay.js";
import BookingComplete from "../components/bookingComplete/bookingComplete.js";

function Home({client_id, admin}) {
  const [squareCredentials, setSquareCredentials] = useState({}) // dict of square credentials: app_id, location_id
  const [selectedDates, setSelectedDates] = useState([]); // list of selected Dates
  const [visibleUnits, setVisibleUnits] = useState([]); // list of units available for selection
  const [selectedUnits, setSelectedUnits] = useState([]); // list of units that are selected
  const [unitInfo, setUnitInfo] = useState({}); // dict of unit info by unit_id for all clientUnits
  const [renterInfo, setRenterInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  }); // dict of renter info
  const [discounts, setDiscounts] = useState([]); // list of valid discounts/PROMO applied
  const [cost, setCost] = useState({}) // dict of cost keys: [deposit, price, discount]
  const [step, setStep] = useState(0); // 0:selections, 1:info, 2:payment, 3:complete, 4:error, 5:loading
  const [renterFormErrors, setRenterFormErrors] = useState({}) // contains errors from renter form
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [bookingComplete, setBookingComplete] = useState(false)

  useEffect(() => {

    helpers.get(`/units?client_id=${client_id}`).then((info) => {
      let updatedUnitInfo = {};
      for (let idx in info) {
        const unit = info[idx];
        updatedUnitInfo[unit.id] = {
          info: unit.info,
          name: unit.name,
          pic: unit.pic,
          cap: unit.cap
        };
      }
      setUnitInfo(updatedUnitInfo);
    })

    helpers.get(`/square_creds?client_id=${client_id}`).then((response) => {
      setSquareCredentials(response[0]);
    })

  }, [client_id])


  useEffect(() => {
    function validateRenterInfo(renterInfo) {
      let errors = {};
      
      if (admin) {
        setRenterFormErrors(errors)
        return 
      }
    
      for (let key of Object.keys(renterInfo)) {
        const value = renterInfo[key];
        console.log('key:', key, 'value:', value);
        
        if (value === '') {
          errors[key] = "This is a required field.";
        } else {
          if (key === 'firstName' || key === 'lastName') {
            if (!/^[a-zA-Z]+(?:[-'’][a-zA-Z]+)?$/.test(value)) {
              errors[key] = "Please enter a valid " + key;
            }
          } else if (key === 'email') {
            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
              errors[key] = "Please enter a valid email address (example@gmail.com)";
            }
          } else if (key === 'phone') {
            if (!/^\d{10}$/.test(value)) {
              errors[key] = "Please enter a valid US 10-digit phone number (0000000000)";
            }
          } else if (key === 'address' || key === 'city') {
            if (!/^[a-zA-Z0-9\s,'’#\-\.]+$/.test(value)) {
              errors[key] = "Please enter a valid " + key;
            }
          } else if (key === 'zip') {
            if (!/^\d{5}(?:-\d{4})?$/.test(value)) {
              errors[key] = "Please enter a valid zip code (00000)";
            }
          }
        }
      }
    
      setRenterFormErrors(errors);
    }
    

    validateRenterInfo(renterInfo);

  }, [renterInfo, admin])

  useEffect(() => {

    console.log(renterFormErrors)
  }, [renterFormErrors])

  

  // async function getPaymentSuccessful(success) {
  //   if (success) {
  //     const body = JSON.stringify({
  //       client_id: client_id,
  //       user: {...renterInfo},
  //       cost: {...cost},
  //       units: [...selectedUnits],
  //       unitInfo: {...unitInfo}
  //     });

  //     const response = await helpers.post('/book_units', body);
  //     console.log(response);
  //     setStep(3);
  //   } else {
  //     setStep(4);
  //   }
  // };

  async function getPaymentSuccessful(success) {
    if (success) {
      setPaymentComplete(true);
      const body = JSON.stringify({
        client_id: client_id,
        user: {...renterInfo},
        cost: {...cost},
        units: [...selectedUnits],
        unitInfo: {...unitInfo}
      });

      const response = await helpers.post('/book_units', body);

      console.log(response);
      if (response.status === "success") {
        setBookingComplete(true);
      }
      setStep(3);
    } else {
      setStep(4);
    }
  };


  return (
      <>
      <ProgressBar 
        step={step} 
        setStep={setStep} 
        selectionsDone={selectedUnits.length > 0} 
        infoDone={renterInfo.lastName !== "" && Object.values(renterFormErrors).length === 0}
        complete={step === 3}
        error={step === 4}
      />
      <div className="booking-container">
        

        
        {step === 0 && (<>
          <BookingCalendar 
            client_id={client_id}
            setSelectedDates={setSelectedDates}
            selectedDates={selectedDates}
            setVisibleUnits={setVisibleUnits} 
            visibleUnits={visibleUnits} 
            setSelectedUnits={setSelectedUnits}
            selectedUnits={selectedUnits}
          />

          <UnitTileList 
            visibleUnits={visibleUnits} 
            setVisibleUnits={setVisibleUnits}
            selectedUnits={selectedUnits}
            setSelectedUnits={setSelectedUnits} 
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            unitInfo={unitInfo}
          />
        </>)}


        {step === 1 && (<>
          <PriceDisplay 
            units={selectedUnits} 
            unitInfo={unitInfo}
            discounts={discounts} 
            setDiscounts={setDiscounts}
            setCost={setCost}
            setStep={setStep}
            client_id={client_id}
          />

          <RenterInfoForm setRenterInfo={setRenterInfo} 
            renterInfo={renterInfo} 
            errors={renterFormErrors}
            setErrors={setRenterFormErrors}
            setStep={setStep}
          />
        </>)}


        {step === 2 && (<>
          <PriceDisplay 
            units={selectedUnits} 
            unitInfo={unitInfo}
            discounts={discounts} 
            setDiscounts={setDiscounts}
            setCost={setCost}
            setStep={setStep}
            client_id={client_id}
          />

          <RenterInfoDisplay
            renterInfo={renterInfo}
            setStep={setStep}
          />

          <UnitPaymentForm
            deposit={cost.deposit.toString()} // ensure deposit is a string
            getPaymentSuccessful={getPaymentSuccessful}
            setStep={setStep}
            step={step}
            squareCredentials={squareCredentials}
            client_id={client_id}
            discounts={discounts}
            setDiscounts={setDiscounts}
          />
          {admin && (
            <button className="btn" onClick={() => {
              setStep(5);
              getPaymentSuccessful(true);
            }}>Book Without Paying</button>
          )}
        </>)}

        {step === 3 && (<>
          <PriceDisplay 
            units={selectedUnits} 
            unitInfo={unitInfo}
            discounts={discounts} 
            setDiscounts={setDiscounts}
            setCost={setCost}
            client_id={client_id}
          />

          <RenterInfoDisplay
            renterInfo={renterInfo}
          />

          <BookingComplete
            email={renterInfo.email}
          />
        </>)}

        {step === 4 && (<>
          <Alert variant={'danger'}>
            <p>There is a problem with your booking, please check your card details and try again. If the issue persists please contact us.</p>
          </Alert>
        </>)}


        {step === 5 && (<>
          <ReactLoading
            className="spinner"
            type="spinningBubbles"
            color="#0000FF"
            height={100}
            width={50}
          />
          <p className="keep-loading-instuctions">Booking Processing: Do Not Leave This Page, this may take up to 1 minute</p>
          <p className="keep-loading-instuctions"><strong>Payment Status:</strong> {!paymentComplete ? "In-Progress...Please wait" : "Complete"}</p>
          <p className="keep-loading-instuctions"><strong>Booking Status:</strong> {!bookingComplete ? "In-Progress...Please wait" : "Complete"}</p>
        </>)}


      </div>
      {step < 2 && <NavBar 
        step={step} 
        setStep={setStep} 
        disabled={() => {
          if (step === 0 && selectedUnits.length > 0) {
            return false;
          } 

          if (step === 1 && (renterInfo.firstName !== "" && Object.values(renterFormErrors).length === 0)) {
            return false;
          }

          return true;
          
        }}
        />}
      </>
    )


  // 
}

export default Home;
