import React, { useEffect, useState } from "react";
import * as helpers from "../../utils/utils.js";
import UnitTile from "../unitTile/unitTile.js";
import "./unitTileList.css"

function UnitTileList({ visibleUnits, setVisibleUnits, setSelectedUnits, selectedUnits, unitInfo, selectedDates, setSelectedDates }) {
  const [sortedDates, setSortedDates] = useState(new Set());
  const [selectMultipleUnitsDates, setSelectMultipleUnitsDates] = useState([]);

  useEffect(() => {

    const sortedUnits = visibleUnits.sort((a, b) => {
      const dateA = helpers.formattedStringToDate(a.date);
      const dateB = helpers.formattedStringToDate(b.date);

      if (dateA > dateB) {
        return 1;
      } 
      return -1;
    });

    let sortedSet = new Set();
    for (let idx in sortedUnits) {
      const unit = sortedUnits[idx]
      
      sortedSet.add(unit.date);
    }

    setSortedDates(sortedSet);
  }, [visibleUnits])
  
  const handleUnitSelectionChange = (unit, date) => {
    if (selectMultipleUnitsDates.includes(date)) {
      if (!selectedUnits.includes(unit)) {
        setSelectedUnits([...selectedUnits, unit]);
      } else {
        setSelectedUnits(selectedUnits.filter(selectedUnit => selectedUnit !== unit));
      }
    } else if (!selectedUnits.includes(unit)) {
        let updatedSelectedUnits = [...selectedUnits];
        updatedSelectedUnits = updatedSelectedUnits.filter(currentUnit => currentUnit.date !== unit.date);
        setSelectedUnits([...updatedSelectedUnits, unit]);
    }
    
  };

  const handleSelectMultipleChange = (date) => {
    if (selectMultipleUnitsDates.includes(date)) {
      let updatedMultipleUnitsDates = selectMultipleUnitsDates.filter((multipleDate) => multipleDate !== date);
      setSelectMultipleUnitsDates(updatedMultipleUnitsDates);
      setSelectedUnits(selectedUnits.filter(unit => unit.date !== date));
    } else {
      setSelectMultipleUnitsDates([...selectMultipleUnitsDates, date])
    }
  }

  return (
    <>
    <div className="selectable-units main-border">
      <p className="section-heading">{'Selections (' + (selectedUnits ? String(selectedUnits.length) : '0') + ')'}</p>
    <div className="unit-selection-instructions">
    </div>
    {Array.from(sortedDates).map((visibleDate) => (
        <div className="date-container secondary-border" key={visibleDate}>
          <div className="date-header">
            <div className="date-options">{(helpers.formattedStringToDate(visibleDate)).toDateString()}</div>
            {(visibleUnits.filter(unit => unit.date === visibleDate).length > 1) && (<div>
              <div>Select the unit you would like to rent.</div>
              <label className="select-multiple">
                <div>Select Multiple Units</div>
                <input name={"select-multiple" + visibleDate} 
                    className="btn select-multiple-checkbox" 
                    type="checkbox"
                    onChange={() => handleSelectMultipleChange(visibleDate)}
                    checked={selectMultipleUnitsDates.includes(visibleDate)} />
              </label>
            </div>)}
            <div className="btn-container">
              <button className="btn remove-btn" 
                  onClick={() => {
                    setSelectedUnits(selectedUnits.filter((unit) => unit.date !== visibleDate));
                    setVisibleUnits(visibleUnits.filter((unit) => unit.date !== visibleDate));
                    setSelectedDates(selectedDates.filter((date) => date !== visibleDate));
                  }}
                >X</button>
            </div>
          </div>
          <div className="tile-container">
          {visibleUnits
            .filter(unit => unit.date === visibleDate)
            .sort((a,b) => helpers.sortUnits(a,b))
            .map((unit, idx) => (
            <label key={unit.unit_id} className="unit-tile-container">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  name={'unit-tile-checkbox-' + visibleDate} 
                  onChange={() => handleUnitSelectionChange(unit, visibleDate)}
                  checked={selectedUnits.includes(unit)}
                />
                {/* {selectedUnits.includes(unit) && <div>Selected For <br/> {helpers.formattedStringToDate(visibleDate).toDateString()}</div>} */}
              </div>
              <UnitTile className="selectable-unit-tile" unit={{...unit, ...unitInfo[unit.unit_id]}} ></UnitTile>
            </label>
          ))}
          </div>
        </div>
    ))}
    </div>
    </>
  );
}

export default UnitTileList;
