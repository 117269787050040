// DiscountForm.js
import React, { useEffect, useState } from 'react';
import DiscountForm from "../discountForm/discountForm.js";
import DisplayHeading from '../displayHeading/displayHeading.js';
import * as helpers from "../../utils/utils.js"
import "./priceDisplay.css"


const PriceDisplay = ({ units, discounts, setDiscounts, setCost, unitInfo, setStep, client_id }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [discountDetails, setDiscountDetails] = useState([]);

  useEffect(() => {
    let price = 0;
    let deposit = 0;

    for (let idx in units) {
      const unit = units[idx];
      price += unit.price;
      deposit += unit.deposit;
    }

    let updatedDiscountDetails = [];
    let updatedTotalDiscountPrice = 0;
    for (let idx in discounts) {
      const discount = discounts[idx]

      if (discount.scope === 'unit') {
        for (let i in units) {
          updatedDiscountDetails.push(
            {'name': discount.name + ' unit #' + units[i].unit_id + ' ' + (units[i].date).slice(0,10),
            'price': discount.price}
          );
          updatedTotalDiscountPrice += discount.price;
        }
      } else {
        updatedDiscountDetails.push(
          {'name': discount.name, 'price': discount.price}
        );
        updatedTotalDiscountPrice += discount.price;
      }
    }

    if (price-updatedTotalDiscountPrice > deposit) {
      price -= updatedTotalDiscountPrice;
    }

    setDiscountDetails(updatedDiscountDetails);
    setTotalPrice(price);
    setTotalDeposit(deposit);
    setCost({'price': price, 'deposit':deposit, 'discount':updatedTotalDiscountPrice});
  }, [units, discounts, setCost])

  
  return (
    <div className='price-display-container main-border'>

       
      <DisplayHeading
        setStep={setStep}
        step={0}
        title={"Selections"}
      />

      {(discountDetails.length > 0 || units.length > 0) && (
        <div className='container cost-details'>

            <table className="table selections-table">
              <tbody>
                {units.map((unit) => (
                  <tr key={unit.unit_id + " " + unit.date}>
                    <td className='unit-description'>
                      <img className="price-image" src={unitInfo[unit.unit_id].pic} alt="rental-unit" width='fit'/>
                      <div>{(helpers.formattedStringToDate(unit.date).toDateString()).slice(0,10)} <br/> {unitInfo[unit.unit_id].name}</div>
                    </td>
                    <td className='price-item'>${unit.price}</td>
                  </tr>
                ))}

                {discountDetails.map((discount) => (
                  <tr key={discount.name}>
                    <td>PROMO: {discount.name}</td>
                    <td className='price-item'>-${discount.price}</td>
                  </tr>
                ))}
                  
              </tbody>
            </table>

            <table className="table price-table">
              <tbody>
              <tr className='cost-display-contianer'>
                  <td>
                    <div>Total Cost</div>
                    <div className='cost-note'>
                      *Additional Fees Apply: Fuel
                    </div>
                  </td>
                  <td className='price-item'>${totalPrice}</td>
                </tr>
                <tr className='deposit-display-contianer'>
                  <td className='indent'>Amount Due on Arrival</td>
                  <td className='price-item'>${totalPrice-totalDeposit}</td>
                </tr>  
                <tr className='deposit-display-contianer'>
                  <td className='indent'>Deposit Due Today</td>
                  <td className='price-item'>${totalDeposit}</td>
                </tr>
              </tbody>
            </table>
        </div>
      )}
      
      
    </div>
  );
};

export default PriceDisplay;
